// import constant
import {
    SET_QR_DETAIL,
    SET_QR_FORM
} from '../constant';

const initialState = {
    formType: '',
    formDisable: true,
    editRecord: {},
    result: []
};

const qrCodeDetail = (state = initialState, action) => {
    switch (action.type) {
        case SET_QR_DETAIL:
            return {
                ...state,
                ...action.data
            };
        case SET_QR_FORM:
            return {
                ...state,
                ...action.data
            };

        default:
            return state;
    }

}

export default qrCodeDetail;