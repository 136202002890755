// import constant
import {
    SET_LANGUAGE_OPTION,RESET_ALL
} from '../constant';

const initialState = [];

const language = (state = initialState, action) => {
    switch (action.type) {
        case SET_LANGUAGE_OPTION:
            return [
                ...state,
                ...action.data
            ];
            case RESET_ALL: {
                return initialState
                }; 
        default:
            return state;
    }

}

export default language;