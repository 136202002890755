// import constant
import {
    SET_CURRENCY,
    RESET_ALL
} from '../constant';

const initialState = [];

const currency = (state = initialState, action) => {

    switch (action.type) {
        case SET_CURRENCY:
            return [
               //...state,
                ...action.data
            ];
            case RESET_ALL: {
                return initialState
                }; 
        default:
            return state;
    }

}

export default currency;