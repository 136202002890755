// import constant
import {
    SET_ANNOUNCEMENT,RESET_ALL
} from '../constant';

const initialValue = []

const announcement = (state = initialValue, action) => {
    switch (action.type) {

        case SET_ANNOUNCEMENT:
            return [
                ...action.data
            ];
            case RESET_ALL: {
                return initialValue
                };  
        default:
            return state;
    }
}

export default announcement;