let key = {};


if (process.env.REACT_APP_MODE == "production") {
  console.log = function () {};

  console.log("Set Production Config");
  const API_URL = "https://p2papi.inwdex.com/";

  key = {
    secretOrKey: "FxUum76z",
    CRYPTO_SECRET_KEY: "1234567812345678",
    RECAPTCHA_SITE_KEY: "6LdmOlcjAAAAAAd_sslKVxx1u5Y3BqsT85cXyE8R",
    API_URL: "https://p2papi.inwdex.com/",
    FRONT_URL: "https://p2p.inwdex.com/",
    ADMIN_URL: "https://controls.inwdex.com/",
    SOCKET_URL: "https://p2papi.inwdex.com/",

    getGeoInfo: "https://ipapi.co/json/",
    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
    indxlinks:{
      home:"https://www.inwdex.com/home",
      exchange:"https://www.inwdex.com/exchange",
      liquidity:"https://www.inwdex.com/liquidity",
      farms:"https://www.inwdex.com/farms",
      pools:"https://www.inwdex.com/pools",
      p2p:"https://www.inwdex.com/p2p"
          },
    socialMedia: {
      telegramUrl: "",
      twitterUrl: "",
      linkedinUrl: "",
      redditUrl: "",
      youtubeUrl: "",
    },
    exchangeurl: "",

    zaakpayurl: "",
  
    MerchantID: "",
    returnUrl: "",
    zaaksecretkey: "",
  };
} else if (process.env.REACT_APP_MODE === "demo") {
  console.log("Set dev env Config");
  const API_URL = "https://indxp2papi.wealwin.com/";

  key = {
    secretOrKey: "",
    CRYPTO_SECRET_KEY: "1234567812345678",
    RECAPTCHA_SITE_KEY: "6LdmOlcjAAAAAAd_sslKVxx1u5Y3BqsT85cXyE8R", 
   
    API_URL: "https://indxp2papi.wealwin.com/",
    FRONT_URL: "https://indxp2p-frontend.pages.dev/",
    ADMIN_URL: "https://indxp2p.alwin.io/indcoin",
    SOCKET_URL: "https://indxp2papi.wealwin.com/",
    getGeoInfo: "https://ipapi.co/json/",
    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
    socialMedia: {
      telegramUrl: "",
      twitterUrl: "",
      linkedinUrl: "",
      redditUrl: "",
      youtubeUrl: "",
    },
    indxlinks:{
      home:"https://www.inwdex.com/home",
      exchange:"https://www.inwdex.com/exchange",
      liquidity:"https://www.inwdex.com/liquidity",
      farms:"https://www.inwdex.com/farms",
      pools:"https://www.inwdex.com/pools",
      p2p:"https://p2p.inwdex.com/"
          },
    exchangeurl: "",
    
    zaakpayurl: "",

    MerchantID: "",
    returnUrl: "",
    zaaksecretkey: "",
  };
} else {
  console.log("Set Development Configssssssssssssssssssssssssssssss");
  const API_URL = "http://localhost"; 
  key = {
    secretOrKey: "FxUum76z",
    CRYPTO_SECRET_KEY: "1234567812345678",
    RECAPTCHA_SITE_KEY: "6LdmOlcjAAAAAAd_sslKVxx1u5Y3BqsT85cXyE8R",
    API_URL: `${API_URL}:2053`,
    SOCKET_URL: `${API_URL}:2053`,
    FRONT_URL: "http://localhost:3000/",

    getGeoInfo: "https://ipapi.co/json/",
    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
    socialMedia: {
      telegramUrl: "",
      twitterUrl: "",
      linkedinUrl: "",
      redditUrl: "",
      youtubeUrl: "",
    },
    indxlinks:{
home:"https://indx.alwin.io/home",
exchange:"https://indx.alwin.io/exchange",
liquidity:"https://indx.alwin.io/liquidity",
farms:"https://indx.alwin.io/farms",
pools:"https://indx.alwin.io/pools",
 p2p:"https://indxp2p.alwin.io/p2p"
    },
    exchangeurl: "http://localhost:3002/home/",
    zaakpayurl: "",
    MerchantID: "",
    returnUrl: "",
    zaaksecretkey: "",
  };
  
}

export default key;

