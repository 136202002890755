// import package
import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { Hidden, Button, Menu, MenuItem } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import SocketContext from "../Context/SocketContext";
import $ from "jquery"
// import action
import { logout } from "../../actions/users";

import { encryptString } from "../../lib/cryptoJS";
import config from "../../config/index";
import { getNotificationHistory, getNotificationHistory_read } from "../../actions/dashboardAction";

// import lib
import { dateTimeFormat } from "../../lib/dateTimeHelper";
import { setTheme } from "../../actions/commonAction";
import ReactHtmlParser from 'react-html-parser';

export default function HeaderLinks(props) {
  const socketContext = useContext(SocketContext);

  const history = useHistory();
  const dispatch = useDispatch();
  // const routeMatch = useRouteMatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE4, setAnchorE4] = React.useState(null);

  // state
  const [data, setData] = useState([]);
  // const [loader, setLoader] = useState(true);
  const [unreadcount, setunreadcount] = useState(0);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick3 = (event) => {
    setAnchorE4(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorE4(null);
  };

  // redux state
  // const tradeThemeData = useSelector((state) => state.tradeTheme);
  const { isAuth } = useSelector((state) => state.auth);
  const { email } = useSelector((state) => state.account);
  const themeData = useSelector((state) => state.theme);

  console.log("isAuthisAuthisAuth", email);
  const NavigateExchange = async (e) => {
    var jwttoken = localStorage.getItem("user_token");
    if (jwttoken) {
      var encryptuserdata = await encryptString(email, true);

      window.location = config.exchangeurl + encryptuserdata;

      // window.open(config.exchangeurl+encryptuserdata)
    }
  };

  // function
  const fetchNotificationHistory = async () => {
    try {
      const {
        status,
        result,
        unread_count,
      } = await getNotificationHistory();
      // setLoader(loading);
      if (status == "success") {
        setData(result);
        setunreadcount(unread_count);
      }
    } catch (err) { }
  };

  useEffect(() => {
    fetchNotificationHistory();
    callsocket();
    loadScript();
  }, []);

  function callsocket() {
    socketContext.socket.on("unreadnotification", (result) => {
      //console.log(result, "Helo12313");
      setunreadcount(result);
    });
  }

  // Notification Dropdown close
  function loadScript() {
    $(document).ready(function () {
      $(document).on('click', '#notificationDropdown', function () {
        $(this).removeClass('show');
      });
      $(document).on('click', 'body', function (e) {
        if (!$(e.target).is('#notificationDropdown'))
          $('#notificationDropdown').removeClass('show');
      })

    });
  }

  return (
    <div className="inner_page_menu">
      <div className="dashboard_login">
        <Hidden mdDown>
          <ul className="list-iline">
            {/* {
              ['/spot/:tikerRoot?', '/derivative/:tikerRoot?'].includes(routeMatch.path) && <li>
                <div className="toggleMode" title="toggle dark mode">
                  <label>
                    <input type="checkbox" checked={tradeThemeData == 'light' ? true : false} name="" onClick={() => setTradeTheme(dispatch, tradeThemeData == 'light' ? 'dark' : 'light')} />
                    <span></span>
                  </label>
                </div>
              </li>
            } */}

            {/* <li>
              <div className="toggleMode" title="toggle dark mode">
                <label>
                  <input
                    type="checkbox"
                    checked={themeData == "light" ? true : false}
                    name=""
                    onClick={() =>
                      setTheme(
                        dispatch,
                        themeData == "light" ? "dark" : "light"
                      )
                    }
                  />
                  <span></span>
                </label>
              </div>
            </li>

          

            {isAuth && (
              <li>
                <Link to="/p2p">P2P</Link>
              </li>
            )}
            {isAuth && (
              <li>
                <Link to="/dashboard">Dashboard</Link>
              </li>
            )}
 {isAuth && (
              <li>
                    <Link to="/P2pwallet">Wallet</Link>
              </li>
            )} */}




            {isAuth && (
              <li>
                {unreadcount > 0 && (
                  <span className="notify_count">{unreadcount}</span>
                )}
                <Button
                  class="btn btnNotification"
                  type="button"
                  data-toggle="collapse"
                  data-target="#notificationDropdown"
                  aria-expanded="false"
                  aria-controls="notificationDropdown"
                  onClick={getNotificationHistory_read}
                >
                  <i className="fas fa-bell"></i>
                </Button>
                <div class="collapse" id="notificationDropdown">
                  <div className="notificationDropdown">
                    <ul>
                      {data.length > 0 &&
                        data.map((item, key) => {
                          return (
                            <li>
                              {/* <p>{dateTimeFormat(item.createdAt)}</p> */}
                              <h5>
                                {ReactHtmlParser(item.description)}@
                                {dateTimeFormat(item.createdAt)}
                              </h5>
                             
                            </li>
                          );
                        })}
                        <Button> <a  class="nav-item nav-link active" href="/dashboard#nav-notificationHistory-tab" >All Notifications</a></Button>
                        {/* <a class="nav-item nav-link active" id="nav-notificationHistory-tab" data-toggle="tab" href="#nav-notificationHistory" role="tab" aria-controls="nav-notificationHistory" aria-selected="true">Notification History</a> */}
                    </ul>
                  </div>
                </div>
              </li>
            )}

            {isAuth && (
              <li>
                <Button
                  aria-controls="profile_menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <i className="fas fa-user"></i>
                </Button>
                <Menu
                  id="profile_menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem>
                    <Link rel="preload" to="/p2p">P2P</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link rel="preload" to="/dashboard">Dashboard</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link rel="preload" to="/P2pwallet">Wallet</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link rel="preload" to="/profile">Profile</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link rel="preload" to="/kyc">KYC</Link>
                  </MenuItem>                  
                  <MenuItem>
                    <Link rel="preload" to="/settings">Settings</Link>
                  </MenuItem>                  
                  <MenuItem>
                    <Link rel="preload" to="/support-ticket">Support</Link>
                  </MenuItem>
                  <MenuItem><Link to="/history">History</Link></MenuItem>
                  <MenuItem>
                    <Link to="#" onClick={() => logout(history, dispatch)}>
                      Logout
                    </Link>
                  </MenuItem>
                </Menu>
              </li>
            )}
          </ul>
        </Hidden>
        <Hidden lgUp>
          <ul className="list-iline">
            {/* <li className="pb-3"><div className="toggleMode" title="toggle dark mode">
              <label>
                <input
                  type="checkbox"
                  checked={themeData == "light" ? true : false}
                  name=""
                  onClick={() =>
                    setTheme(
                      dispatch,
                      themeData == "light" ? "dark" : "light"
                    )
                  }
                />
                <span></span>
              </label>
            </div></li> */}
            {/* {isAuth && (
              <li>
                <Link to="/spot">Spot</Link>
              </li>
            )} */}

            <li>
              <Link to="/home" color="transparent" className="nav-link">Home</Link>
            </li>
            <li>
              <Link to="/exchange" color="transparent" className="nav-link">Exchange</Link>
            </li>
            <li>
              <Link to="/liquidity" color="transparent" className="nav-link">Liquidity</Link>
            </li>
            <li>
              <Link to="/farms" color="transparent" className="nav-link">Farms</Link>
            </li>
            <li>
              <Link to="/pools" color="transparent" className="nav-link">Pools</Link>
            </li>



            {isAuth && (
              <li>
                <Link to="/p2p">P2P</Link>
              </li>
            )}
            {isAuth && (
              <li>
                <Link to="/dashboard">Dashboard</Link>
              </li>
            )}

            {/* {isAuth && (
              <li>
                <Link to="#" onClick={NavigateExchange}>
                  ICO                  
                </Link>
              </li>
            )} */}
            {isAuth && (
              <li>
                <Link to="/P2pwallet">Wallet</Link>
              </li>
            )}

            {/* {isAuth && (
              <li>
                <Link to="/settings">Staking</Link>
              </li>
            )} */}

            {/* {
              isAuth && <li>
                <Link to="">Notifications</Link>
              </li>
            } */}

            {isAuth && (
              <li>
                <Link to="/profile">Profile</Link>
              </li>
            )}

            {isAuth && (
              <li>
                <Link to="/kyc">KYC</Link>
              </li>
            )}

            {isAuth && (
              <li>
                <Link to="/settings">Settings</Link>
              </li>
            )}

            {/* {isAuth && (
              <li>
                <Link to="/history">History</Link>
              </li>
            )} */}

            {isAuth && (
              <li>
                <Link to="/support-ticket">Support</Link>
              </li>
            )}

            {/* {
              isAuth && <li>
                <Link to="/api-management">API Management</Link>
              </li>
            } */}

            {isAuth && (
              <li>
                <Link to="#" onClick={() => logout(history, dispatch)}>
                  Logout
                </Link>
              </li>
            )}
          </ul>
        </Hidden>
      </div>
    </div>
  );
}
