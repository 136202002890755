// import constant
import {
    SET_SPOT_TRADE_PAIR_LIST,RESET_ALL
} from '../constant';

const initialValue = []

const pairList = (state = initialValue, action) => {
    switch (action.type) {

        case SET_SPOT_TRADE_PAIR_LIST:
            return [
                ...action.data
            ];
            case RESET_ALL: {
                return initialValue
                };
        default:
            return state;
    }
}

export default pairList;