/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import { Button } from "@material-ui/core";
import { toastAlert } from "../../lib/toastAlert";
// import  AdPopup from "./AdPopup"; 
import store from "../../store";
import isLogin from "../../lib/isLogin";
import { getAuthToken } from "../../lib/localStorage";
import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import config from '../../config'
// import action
import { getLanguage, getSiteSetting,sendNewsletter } from '../../actions/commonAction';

// import lib
import { capitalize } from '../../lib/stringCase';
import { setLang, getLang } from '../../lib/localStorage';
import isEmpty from "../../lib/isEmpty";
import $ from "jquery"

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const initialFormValue = {
    email: "",
  }
  // state
  const [langOption, setLangOption] = useState([])
  const [language, setLanguage] = useState('')
  const [setting, setSetting] = useState('')
  const [advflag, setAdvflag] = useState('false')
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const { isAuth } = store.getState().auth;
  const {email}=formValue
  // function
  const handleLanguage = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setLanguage(value)
    setLang(value)
    i18n.changeLanguage(value);
  }
  const settingData = async () => {
    try {
      const { status, loading, result } = await getSiteSetting(dispatch);

      if (status == 'success') {
        setSetting(result);
      }
    } catch (err) { }
  }
  const fetchLanguage = async () => {
    try {
      const { status, loading, result } = await getLanguage(dispatch);
      if (status == 'success') {
        setLangOption(result);
        let lang = getLang();
        if (isEmpty(lang)) {
          let primaryData = result && result.length > 0 && result.find((el => el.isPrimary == true))
          if (primaryData) {
            setLanguage(primaryData.code)
            setLang(primaryData.code)
            i18n.changeLanguage(primaryData.code);
          }
        } else {
          setLanguage(lang)
        }
      }
    } catch (err) { }
  }
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

   console.log( e.target,'target')
    let formData = { ...formValue, ...{ [name]: value } };
    console.log(formData,'formData')
    setFormValue(formData);
    
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
   let reqData = {
     email:email
   }
   const {status,message ,error} = await sendNewsletter(reqData)
   if (status == false) {
    let err = {
      email: error,
    };
    setValidateError(err);
  } 
  console.log(typeof(status),'stattttt')
  if(status==true) {
 
    setValidateError({});
    console
    setFormValue(initialFormValue)
    toastAlert("info", message, "login");
   
  }
  }
  useEffect(() => {
    fetchLanguage();
    settingData();

    // if (isAuth != true && isLogin()) {
    // } else {
    // // window.$('#exampleModalCenter').modal('show') // to show 

    // }

  }, [])


  return (
    <footer className={footerClasses + " footer_home"}>
      <div className={classes.container}>
        <div className="footer_top_home">
          <GridContainer>
            <GridItem sm={12} md={3}>
              <img src={require("../../assets/images/footer_logo.png")} alt="logo" className="mb-3 img-fluid" />
              <p className="footer_about">Infiniteway is a next-generation DEX platform that offers unlimited trading possibilities with ultra-low fees, lightning-fast speeds, and seamless user experience. Join us and experience the infinite world of decentralized trading.</p>
            </GridItem>
            <GridItem sm={4} md={3}>
              <h2>Products</h2>
              <ul className="footer_menu_list">
                <li><a href={config.indxlinks.exchange}>Exchange</a></li>
                <li><a href={config.indxlinks.liquidity}>Liquidity</a></li>
                <li><a href={config.indxlinks.farms}>Farms</a></li>
                <li><a href={config.indxlinks.pools}>Pools</a></li>
                <li><a href={config.indxlinks.p2p}>P2p</a></li>
              </ul>
            </GridItem>
            <GridItem sm={4} md={3}>
              <h2>Quick Links</h2>
              <ul className="footer_menu_list">
                <li><a href="/privacy-policy">Privacy Policy</a></li>
                <li><a href="/terms">Terms & Conditions</a></li>
                <li><a href="/faq">FAQ</a></li>
              </ul>
            </GridItem>
            <GridItem sm={12} md={3}>
              <h2>Subscribe Now</h2>
              <label className="subscribe_label">Get the latest news and updates</label>
              <div className="subscribe_form">
                <input name="email" type="email" onChange={handleChange} placeholder="Enter your email address" value={email} className="subscribe_inp"/>
                <Button className="subscribe_btn"  onClick={handleSubmit}><img src={require("../../assets/images/send_icon.png")} alt="logo"/></Button>
              </div>
              {validateError.email && <p className="error-message">{(validateError.email)}</p>}
              <div className="footer_social_links">
                <ul>
                {setting && setting.facebookstatus && setting.facebookstatus == true &&  <li><a href={setting && setting.facebookLink} target="_blank"><i className="fab fa-facebook-f"></i></a></li>}
                {setting && setting.twitterstatus && setting.twitterstatus == true &&   <li><a href={setting && setting.twitterUrl} target="_blank"><i className="fab fa-twitter"></i></a></li>}
                   {setting && setting.youtubestatus && setting.youtubestatus == true && <li><a href={setting && setting.youtubelink} target="_blank"><i className="fab fa-youtube"></i></a></li>}
                   {setting && setting.linkedinstatus && setting.linkedinstatus == true &&  <li><a href={setting && setting.linkedinLink} target="_blank"><i className="fab fa-linkedin"></i></a></li>}
                   {setting && setting.telegramstatus && setting.telegramstatus == true &&  <li><a href={setting && setting.telegramlink} target="_blank"><i className="fab fa-telegram-plane"></i></a></li>}
                </ul>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className="footer_bottom_home">
          <div>
            <p className="copyryt_txt mb-0">Inwdex - &copy; Copyright 2023. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
