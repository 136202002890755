
import React, { useEffect } from "react";

// import components
import HeaderLinks from "../components/Header/HeaderLinks.js";
import Header from "../components/Header/Header.js";
import Footer from "../components/Footer/Footer.js";
import Contactus from "../components/Contactus/contactus";
import { useSelector } from 'react-redux';
const dashboardRoutes = [];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const HomePage = (props) => {
  const { ...rest } = props;
  const theme = useSelector(state => state.theme)
  return (
    <div className="page_wrap">
      <ScrollToTopOnMount />
      <Header className="header"
        color="transparent"
        routes={dashboardRoutes}
        brand={
          theme == 'dark' ? <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /> : <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />
      }
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "dark",
        }}
        {...rest} />
        <Contactus/>
      <Footer />
    </div>
  );
}

export default HomePage;