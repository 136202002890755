// import constant
import {
    SET_UPI_DETAIL,
    SET_UPI_FORM
} from '../constant';

const initialState = {
    formType: '',
    formDisable: true,
    editRecord: {},
    result: []
};

const upiDetail = (state = initialState, action) => {
    switch (action.type) {
        case SET_UPI_DETAIL:
            return {
                ...state,
                ...action.data
            };
        case SET_UPI_FORM:
            return {
                ...state,
                ...action.data
            };

        default:
            return state;
    }

}

export default upiDetail;