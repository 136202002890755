export default {
    "REGISTER_TITLE_1": "Register your account easily and start trading.",
    "ACCEPT_TERMS_MESSAGE": "Please accept the Terms & Conditions of Use",
    "RECOVERY_PASSWORD_TITLE": "Enter your Id associated with your Inwdex Exchange account and we'll send you a link to set a new password.",
    "EMAIL_NOT_EXISTS": "User account not found",
    "SIGN_IN_BUTTON": "Sign In",
    "EMAIL_PLACEHOLDER": "Enter Email Address",
    "KEEP_SIGN_COMPUTER": "Keep me signed in on this computer",
    "DON'T_HAVE_ACCOUNT": "Don't have an account",
    "EMAIL_REQUIRED": "Email field is required",
    "EMAIL_INVALID": "Email is invalid",
    "FORGOT_PASSWORD": "Forgot password",
    "PASSWORD_PLACEHOLDER": "Enter Password",
    "PASSWORD_REQUIRED": "Password field is required",
    "PASSWORD_INCORRECT": "Password incorrect",
    "PASSWORD_SAME": "You have entered current Password ",
    "PASSWORD_MIN_MAX": "Password should contain atleast one uppercase, atleast one lowercase, atleast one number, atleast one special character and minimum 6 and maximum 18",
    "CONFIRM_PASSWORD_MISMATCH": "Passwords must match",
    "PASSWORD_CHANGE_SUCCESS": "Password Reset Successfully",
    "CURRENT_PASSWORD_REQUIRED": "Current Password field is required",
    "CONFIRM_PASSWORD_REQUIRED": "Confirm password field is required",
    "UPDATE_PASSWORD": "Update Login Password",
    "CURRENT_PASSWORD": "Current Password",
    "NEW_PASSWORD": "New Password",
    "CONFIRM_PASSWORD": "Confirm New Password",
    "REQUIRED": "Required",
    "INVALID_CODE": "Invalid Code",
    "INVALID_OTP": "Invalid OTP ",
    "OTP_EXPIRED": "OTP has been Expired !!",
    "TERMS_REQUIRED": "Terms field is required",
    "PERSONAL_DETAILS": "Personal Details",
    "FULL_NAME": "Full Name",
    "RESIDENTIAL_ADDRESS": "Residential Address",
    "BUILDING_BLOCK": "Building / Block Name",
    "ADDRESS": "Address",
    "STATE_PROVISION": "State / Province",
    "CITY": "City",
    "POSTAL_CODE": "Postal Code",
    "COUNTRY": "Country",
    "EDIT_PROFILE": "Edit my Profile",
    "SUBMIT": "Submit",
    "FIRST_NAME": "First Name",
    "LAST_NAME": "Last Name",
    "NAME": "Name",
    "PROFILE_EDIT_SUCCESS": "Thank you, your profile has been updated!",
    "SOMETHING_WRONG": "Something went wrong",
    "NO_DATA": "There is no data",
    "NO_ORDER": "There is no order",
    "ORDER_CANCEL": "Your Order cancelled successfully",
    "ORDER_ALREADY_COMPLETED": "Your Order already completed",
    "ORDER_ALREADY_CANCEL": "Your Order already cancelled",
    "REGISTER_BANK_ACCOUNT": "Here you can register your bank account within the system which will allow you to withdraw funds from Inwdex Exchange",
    "ADD_BANK_ACCOUNT": "Add Bank Account",
    "ADD_BANK": "Add Bank",
    "MY_BANK_ACCOUNT": "My Bank Accounts",
    "REGISTER_UPI_ACCOUNT": "You have not registered any UPI ID. Please provide an UPI ID in order to withdraw funds",
    "REGISTER_QR_ACCOUNT": "You have not registered any GPay QR code. Please provide QRCode in order to withdraw funds",
    "ADD_QR_ACCOUNT": "Add QR Code",
    "QR_ACCOUNT": "GPay Account",
    "QR_NAME": "QR Code",
    "ADD_UPI_ACCOUNT": "Add UPI ID",
    "UPI_ACCOUNT": "UPI Account",
    "UPI_NAME": "UPI Id",
    "DEFAULT": "Default",
    "BANK_ACCOUNT": "Bank Account",
    "BANK_NAME": "Bank Name",
    "ACCOUNT_NO": "Account No",
    "ACCOUNT_NUMBER": "Account Number",
    "ACCOUNT_NUMBER_EUR": "IBAN Account Number",
    "ACCOUNT_HOLDER_NAME": "Account Holder Name",
    "ROUTING_NUMBER": "Routing number(s)",
    "BIC_SWIFT_CODE": "BIC/SWIFT Code",
    "IBAN_CODE": "IBAN Code",
    "IFSC_CODE": "IFSC Code",
    "BANK": "Bank",
    "EMAILEMAIL": "Email",
    "TRANSACTION_CREATEDAT": "Transaction Date",
    "STATUS": "Status",
    "TWO_FA": "Two Factor Authentication",
    "EMAIL_OTP_NOTIFY": "Enter the OTP sent to Email for login",
    "EDIT": "Edit",
    "CREATED_AT": "CreatedAt",
    "REFFRAL_COMMISSION": "Referral Bonus",
    "DELETE": "Delete",
    "TWO_FA_MSG": "You have to enable 2FA or Security code in order to withdraw fiat funds.",
    "BANK_EDIT_SUCCESS": "Bank detail updated successfully",
    "BANK_ADD_SUCCESS": "Bank detail added successfully",
    "BANK_DELETE_SUCCESS": "Bank detail deleted successfully",
    "BANK_SET_PRIMARY_SUCCESS": "Default bank details updated successfully",
    "UPDATE": "Update",
    "NOTES": "Notes",
    "PASSWORD_TITLE": "For security measures, your password should contain:",
    "PASSWORD_DESCRIPTION1": "a minimum of 6 characters long",
    "PASSWORD_DESCRIPTION2": "at least one UPPERCASE letter (A, B, X, Y...)",
    "PASSWORD_DESCRIPTION3": "at least one digit (0, 1,2, 9...)",
    "PASSWORD_DESCRIPTION4": "at least one special character (! @ # $ ^ *...)",
    "PASSWORD_DESCRIPTION5": "at least one LOWERCASE letter (a, b, x, y...)",
    "PASSWORD_DESCRIPTION6": "a maximum of 18 characters long",

    "TWO_FA_DESCRIPTION1": `Enable Two Factor Authentication as an additional security measure, to protect your funds and be able to withdraw. Please use Google Authenticator
    which you can`,
    "TWO_FA_DESCRIPTION2": `Please download the google authenticator by clicking "Download here" and scan the QR code. You will be receiving a 6 digit code, which need to enter in 2FA box.`,
    "TWO_FA_DESCRIPTION3": "Right down the 32 digit code in a white paper for your security if you forgot the 2FA code to withdraw funds",
    "DOWNLOAD_HERE": "Download Here",
    "HOW_ENABLE": "How to Enable",
    "ENABLE": "Enable 2FA",
    "DISABLE": "Disable 2FA",
    "ENTER_TWO_FA_CODE": "Enter 6 Digit 2FA Code or your security pin",
    "ENTER_EMAIL_OTP": "Enter OTP Received on Mail",
    "ENTER_PHONE_OTP": "Enter OTP Received on Your Phone",
    "TWO_FA_CODE": "Your 32 Digit Security Code",
    "SCAN_QR_CODE": "Scan the QR Code",
    "DISABLED": "Disabled",
    "ENABLED": "Enabled",
    "TWO_FA_ENABLE_SUCCESS": "2FA enabled successfully",
    "TWO_FA_DISABLE_SUCCESS": "2FA disabled successfully",
    "EDIT_SETTING_SUCCESS": "Setting updated successfully",
    "IDENTITY_DOCUMENT": "Identity Document",
    "IDENTIFICATION_DOCUMENT": "Identification Document",
    "SELECTED_ID_NUMBER": "Enter the selected document ID number",
    "FRONT_SIDE": "Front Side",
    "PICTURE_SCAN": "Picture or scan showing the 4 corners",
    "MAX_1MB_IMG": "Max 1MB in jpg/png/JPEG",
    "BACK_SIDE": "Back Side",
    "SELFIE_SELECTED_ID": "Selfie with Selected ID",
    "IDENTITY_DOCUMENT_TITLE1": "Here you can verify your identity by uploading one of the approved identity documents",
    "IDENTITY_DOCUMENT_DESCRIPTION1": "All Images should be clearly visible and text also clearly visible",
    "IDENTITY_DOCUMENT_DESCRIPTION2": "If not readable text, unclear face, blur image should be disqualified and the status will be not verified.",
    "IDENTITY_DOCUMENT_DESCRIPTION3": "The selfie or picture with  your selected ID must show clear face holding the selected ID. Please see an example",
    "IDENTITY_DOCUMENT_DESCRIPTION4": "If you want to change your identity document please contact our",
    "IDENTITY_HINT1": "Max. 2MB size, jpg, png,JPEG pdf allowed",
    "IDENTITY_HINT2": "Max 2MB in jpg/png/pdf/JPEG",
    "HERE": "Here",
    "IDENTITY_DOCUMENT_VERIFIED": "Your ID document has been submitted",
    "IDENTITY_DOCUMENT_VERIFIED1": "Your ID document has been verified",
    "SUPPORT_TEAM": "support team",
    "ADDRESS_DOCUMENT_TITLE1": "Address Proof",
    "ADDRESS_DOCUMENT_TITLE2": "Proof",
    "ADDRESS_DOCUMENT_TITLE3": "Upload the document",
    "ADDRESS_DOCUMENT_DESCRIPTION1": "- The document must clearly indicate your full name and full address.",
    "ADDRESS_DOCUMENT_DESCRIPTION2": "- Document should not be older than 3 months",
    "ADDRESS_DOCUMENT_DESCRIPTION3": "- Approved utility bill electricity bill, gas bill, landline phone, internet bill. Mobile phone bill not accepted. ",
    "ADDRESS_DOCUMENT_DESCRIPTION4": "Utility Bill (Approved utility bills: electricity bill, gas bill, landline phone, internet bill). Mobile phone not accepted.",
    "ADDRESS_DOCUMENT_DESCRIPTION5": "If you want to change your Address Proof document please contact our",
    "ADDRESS_DOCUMENT_VERIFIED": "Your Address Proof has been submitted",
    "ADDRESS_DOCUMENT_VERIFIED1": "Your Address Proof has been verified",
    "DRIVING_LICENSE": "Driving License",
    "UTILITY_BILL": "Utility Bill",
    "PAN_CARD": "Pan Card",
    "BANK_STATEMENT": "Bank Info",
    "CREDIT_CARD_STATEMENT": "Credit card statement",
    "MUNICIPALITY_REGISTRATION": "Municipality Registration",
    "GOVERNMENT_ISSUED_ID": "Government issued ID",
    "PASSPORT": "Passport",
    "PANCARD": "Pancard",
    "GAS_BILL": "Gas Bill",
    "AADHAR_CARD": "Aadhar Card",
    "TOO_LARGE": "Too large size",
    "INVALID_IMAGE": "Please select valid format",
    "IDENTITY_DOC_UPLOAD_SUCCESS": "Identity document updated successfully",
    "ADDRESS_DOC_UPLOAD_SUCCESS": "Residential document updated successfully",
    "NOT_APPLY": "Not Apply",
    "VERIFIED": "Verified",
    "NOT_VERIFIED": "Not Verified",
    "REJECTED": "Rejected",
    "BASIC": "Basic",
    "ADVANCED": "Advanced",
    "PRO": "Pro",
    "BASIC_PENDING": "Basic Pending",
    "ADVANCED_Pending": "Advanced Pending",
    "PRO_PENDING": "Pro Pending",
    "BASIC_USER": "Basic User",
    "ADVANCED_USER": "Advanced User",
    "PRO_USER": "Pro User",
    "TYPE_BASIC_DESCRIPTION1": "Deposit crypto up to 10,00 € in value",
    "TYPE_BASIC_DESCRIPTION2": "Trade crypto and fiat markets",
    "TYPE_BASIC_DESCRIPTION3": "Withdraw crypto and fiat up to 10,00  € in value for maximum $2500",
    "ID_ADDRESS_PROOF": "ID & Permanent Account Proof",
    "DEFAULT_BANK_ACCOUNT_DETAIL": "Bank Account Proof (only default withdraw bank)",
    "USER_ID": "User ID",
    "MEMBER_SINCE": "Member Since",
    "VERIFICATION_TYPE": "Verification Type",
    "EMAIL_PHONE": "Email Address & Phone",
    "VERIFY_NOW": "Verify Now",
    "PENDING": "Pending",
    "WITHDRAW_ACCOUNT": "Withdraw Account",
    "FIAT_WITHDRAW_DESCRIPTION1": "Minimal Withdraw",
    "FIAT_WITHDRAW_DESCRIPTION2": "Withdraw will take 24-48 hrs due to bank network / holidays",
    "FINAL_WITHDRAW_AMOUNT": "Final Withdraw Amount",
    "WITHDRAW_FEE": "Withdraw Fee : ",
    "WITHDRAW_AMOUNT": "Withdraw Amount",
    "WITHDRAW_ADDRESS": "Withdraw Address",
    "ALLOW_NUMERIC": "Only allowed numeric",
    "BALANCE": "Balance",
    "INSUFFICIENT_BALANCE": "Insufficient balance",
    "INVALID_AMOUNT": "Invalid Amount",
    "KYC_SUBMIT_ALERT": "Please submit your kyc details",
    "INVALID_BANK_ACCOUNT": "Invalid bank account",
    "VERIFICATION_LINK": "Verification link sent to email address",
    "INVALID_TOKEN": "Invalid Token",
    "EXPIRY_TOKEN": "Expiry token",
    "DEPOSIT_QR_CODE": "To deposit use this QR",
    "COIN_ADDRESS": "Your Coin Address",
    "COPY_ADDRESS": "Copy Address",
    "COIN_DEPOSIT_DESCRIPTION1": "Send only {{CURRENCY_SYMBOL}} to this deposit Address.",
    "COIN_DEPOSIT_DESCRIPTION2": "Minimal Deposit: 0.001 LTC",
    "COIN_DEPOSIT_DESCRIPTION4": "Send only ERC20 tokens to this Address",
    "COIN_DEPOSIT_DESCRIPTION3": "Sending coin or token other than {{CURRENCY_SYMBOL}} to this address may result in the loss of your deposit.",

    "COIN_DEPOSIT_DESCRIPTION5": "Withdraw will take 24-48 hrs due to admin verification",
    "FIAT_DEPOSIT_DESCRIPTION1": "Minimal Deposit: €10",
    "FIAT_DEPOSIT_DESCRIPTION2": "Must upload proof of deposit in pdf / jpg and enter reference number / ID",
    "fiat_deposit": "Fiat Deposit",
    "coin_deposit": "Crypto Deposit",
    "fiat_withdraw": "Fiat Withdraw",
    "coin_withdraw": "Crypto Withdraw",
    "ADMIN_ACCOUNT": "Admin Account",
    "DEPOSIT": "Deposit",
    "DEPOSIT_AMOUNT": "Deposit Amount",
    "UPLOAD_PROOF": "Upload Proof",
    "DEPOSIT_REQUEST_SUCCESS": "Deposit request sent successfully",
    "FINAL_DEPOSIT_AMOUNT": "Final Deposit Amount(Inc Fee 3%)",
    "DEPOSIT_TOO_LOW": "Deposit amount too low",
    "SELECT_CURRENCY": "Select Currency",
    "INVALID_WALLET_TYPE": "Invalid wallet type",
    "WALLET_MIS_MATCH": "From wallet and to wallet should be different",
    "WALLET_TRANSFER_SUCCESS": "Wallet conversion process completed successfully",
    "OPEN_ORDER": "Open Orders",
    "CANCEL": "Cancel",
    "PRICE": "Price",
    "STOP_LIMIT": "Stop-limit",
    "STOP_MARKET": "Stop-market",
    "TRAILING_STOP": "Trailing-stop",

    // Setting
    "DEFAULT_THEME": "Default Theme for Trading",
    "LIGHT_THEME": "Light Theme",
    "DARK_THEME": "Dark Theme",

    // General Settings
    "GENERAL_SETTINGS": "General Settings",

    // Wallet Page
    "YOUR_BALANCE": "Your Balance",
    "ESTIMATED_VALUE": "Estimated Value:",
    "TOTAL_BALANCE": "Total Account Balance",
    "TRANSFER_FUND": "Transfer funds",
    "AMOUNT": "Amount",

    // Home Page
    "WELCOME_TO": "Welcome to",
    "BANNER_DESCRIPTION_1": `We put the power in your hands to `,
    "BANNER_DESCRIPTION_2": "buy, sell and trade digital currency.",
    "GET_STARTED": "Sign up now",
    "EXPLORE_MARKETS": "Explore Our Markets",
    "EXPLORE_MARKETS_DESCRIPTION_1": "The World's Leading Binetco Currency Exchange",
    "EXPLORE_MARKETS_DESCRIPTION_2": "Trade Bitcoin, ETH, and hundreds of other Binetco Currencies in minutes.",
    "BUY": "Buy",

    // Staking
    "STAKING": "Staking",
    "TRANSFER": "Transfer",
    "WALLET_BALANCE": "Wallet Balance",
    "INTERESTED_AMT_STAKE": "Interested Amount to Stake",
    "STAKE_AMOUNT": "Stake Amount",
    "STAKE_CONDITION_1": "I have read the",
    "STAKE_CONDITION_2": "terms & conditions",
    "TRANSFER_CONFIRM": "Transfer Confirm",
    "STAKING_DETAIL": "Staking Details",
    "APY": "APY",
    "FLEXIBLE_INTEREST_THOUSAND": "Flexible Interest Per Thousand",
    "FLEXIBLE_INTEREST": "Flexible Interest",
    "LOCKED_INTEREST": "Locked Interest",
    "MIN_SUBSCRIPTION": "Minimum subscription",
    "MAX_SUBSCRIPTION": "Maximum subscription",
    "TOTAL_INTEREST": "Total interest",
    "REDEMPTION_PERIOD": "Redemption Period",
    "DAYS": "Days"
}