// import package
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { moment } from 'moment';
//import Moment from 'react-moment';
import ReactDatatable from "@ashvin27/react-datatable";
import moment from "moment";
import StaticNavbar from "components/StaticNavbar";

// @material-ui/core components
import { useHistory } from "react-router-dom";
import Header from "components/Header/Header.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import "react-datepicker/dist/react-datepicker.css";

// import action
// import { getKycDetail} from "../../actions/userKyc";

import {
  // getP2PPairList,
  postTrade,
  closeTradeDateCrossed,
  getMyRecentaddData,
  updateTrade,
  cancelMyadAction,
} from "../../actions/p2paction";

// import lib
import { toastAlert } from "../../lib/toastAlert";
//import { transactionStatus } from '../../lib/displayStatus';

const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const initialFormValue = {
  currencyselected: "BTC",
  posttocurrency: "USD",
  postprefcurrency: "Bank",
  postminlimit: "",
  postmaxlimit: "",
  posttooptions: [],
  postcheckboxaccept: false,
  BuyorSell: "Buy",
  quantity: 0,
  price: "",
  postId: "",
  secondamount: "",
  country:"",
  comments:""
};

export default function MyAds(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  // const [userBalance, setUserBalance] = useState(0);
  // const [balancedetails, setBalancedetails] = useState([]);
  const [spotpairsdata, setSpotpairsdata] = useState([]);
  // const [curmarketprice, setCurmarketprice] = useState(0);
  const [posttradeprice, setPosttradeprice] = useState(0);
  const [userId, setUserId] = useState("");
  const [myaddDetails, setMyaddDetails] = useState([]);

  const {
    currencyselected,
    posttocurrency,
    postprefcurrency,
    postminlimit,
    postmaxlimit,
    postcheckboxaccept,
    BuyorSell,
    quantity,
    price,
    postId,
    country,
    comments
  } = formValue;


  const configdata = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Users",
    no_data_text: 'No user found!',
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last"
      }
    },
    show_length_menu: false,
    show_filter: false,
    show_pagination: true,
    show_info: true,
    defaultSortAsc: true,
  };

  // redux-state
  const userKyc = useSelector((state) => state.userKyc);
  const walletData = useSelector((state) => state.wallet);
  const authDetails = useSelector((state) => state.auth);

  // const handleChange = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };
  const { ...rest } = props;

  // Handle change for add the values
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    //setValidateError(validation(formData))
    if (name === "quantity") {
      let curprice = price * value;
      setPosttradeprice(curprice);
    }
    if (name === "price") {
      let curprice1 = quantity * value;
      setPosttradeprice(curprice1);
    }
  };


  const updateMyad = async (e) => {
    console.log("formvalue---", e);

    window.$("#edit_post").modal("show");
    if (e && e != undefined) {
      var totprice = e._doc.maxlimit / e._doc.quantity;
     console.log(e._doc.country,'eeeeeeeee')
      let formData = {
        ...formValue,
        ...{
          postprefcurrency: e._doc.transferMode,
          postminlimit: e._doc.minlimit,
          postmaxlimit: e._doc.maxlimit,
          quantity: e._doc.quantity,
          price: totprice,
          postId: e._doc._id,
          currencyselected: e._doc.firstCurrency,
          BuyorSell: e._doc.BuyorSell,
          country:e._doc.country,
          comments:e._doc.comments
        },
      };
      setPosttradeprice(e._doc.maxlimit);
      setFormValue(formData);
    }
  };
  const cancelMyad = async (e) => {
    window.$("#cancel_post").modal("show");
    if (e && e != undefined) {
      // setCurrentPost(e)
      let formData = {
        ...formValue,
        ...{
          postprefcurrency: e._doc.transferMode,
          postminlimit: e._doc.minlimit,
          postmaxlimit: e._doc.maxlimit,
          price: e._doc.price,
          postId: e._doc._id,
          currencyselected: e._doc.firstCurrency,
          BuyorSell: e._doc.BuyorSell,
        },
      };
      setFormValue(formData);
    }
  };
  const updatePost = async (e) => {
    e.preventDefault();
    e.preventDefault();
    let reqData = {
      postId: postId,
      userId: userId,
      price: price,
      quantity: quantity,
      minlimit: postminlimit,
      maxlimit: posttradeprice,
      postprefcurrency: postprefcurrency,
      comments:comments
    };
    let { status, message, error } = await updateTrade(
      reqData,
      dispatch
    );
    if (status == "success") {
      window.$("#edit_post").modal("hide");

      setFormValue(initialFormValue);
      toastAlert("success", message, "login");
      // history.push("/p2p");
      getMyads(authDetails.userId);
    } else {
      if (error) {
        setValidateError(error);
      }
      toastAlert("error", message, "login");
    }
  };
  const cancelPost = async (e) => {
    e.preventDefault();
    e.preventDefault();
    let reqData = {
      postId: postId,
      userId: userId,
      price: price,
      minlimit: postminlimit,
      maxlimit: postmaxlimit,
      postprefcurrency: postprefcurrency,
    };

    let { status, message, error } = await cancelMyadAction(
      reqData,
      dispatch
    );
    if (status == "success") {
      window.$("#cancel_post").modal("hide");

      setFormValue(initialFormValue);
      toastAlert("success", message, "login");
      // history.push("/p2p");
      getMyads(authDetails.userId);
    } else {
      if (error) {
        setValidateError(error);
      }
      toastAlert("error", message, "login");
    }
  };
  // const handleposttocurrency = (e) => {
  //   e.preventDefault();
  //   const { name, value } = e.target;

  //   let formData = { ...formValue, ...{ [name]: value } };
  //   setFormValue(formData);
  //   var pair = currencyselected + value;
  //   var tempprice = 0;
  //   var curindex = spotpairsdata.findIndex((x) => x.tiker_root === pair);
  //   if (curindex != -1) {
  //     tempprice = spotpairsdata[curindex].index_price;
  //     setCurmarketprice(tempprice);
  //   }
  //   var totPrice = quantity * curmarketprice;
  //   setPosttradeprice(totPrice);
  // };

  const handlepostprefcurrency = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
  };



  const validPositive = (e) => {
    if (
      new RegExp(`^\\d*(\\.\\d{0,2})?$`).test(e.target.value) ||
      (e.target.value = "")
    ) {
      e.preventDefault();
    }
  };

  // const postSubmit = (e)=>{
  // const postSubmit = async (e) => {
  //   e.preventDefault();

  //   // alert();
  //   try {
  //     let reqData = {
  //       userId: userId,
  //       fromcurrency: currencyselected,
  //       tocurrency: posttocurrency,
  //       posttradeprice: posttradeprice,
  //       type: BuyorSell,
  //       minlimit: postminlimit,
  //       maxlimit: postmaxlimit,
  //       quantity: quantity,
  //       postcheckboxaccept: postcheckboxaccept,
  //       postprefcurrency: postprefcurrency,
  //       startDate: startDate,
  //     };

  //     const validateError = await validation(reqData);
  //     if (!isEmpty(validateError)) {
  //       setValidateError(validateError);
  //     } else {
  //       setValidateError("");
  //       window.$("#confirm_post").modal("show");
  //     }
  //   } catch (err) {
  //     console.log("errrrr", err);
  //   }
  // };

  const confirmPost = async (e) => {
    alert(e.target.value);
    e.preventDefault();
    let reqData = {
      userId: userId,
      fromcurrency: currencyselected,
      tocurrency: posttocurrency,
      posttradeprice: posttradeprice,
      type: BuyorSell,
      minlimit: postminlimit,
      maxlimit: postmaxlimit,
      quantity: quantity,
      postcheckboxaccept: postcheckboxaccept,
      postprefcurrency: postprefcurrency,
      startDate: startDate,
    };

    let { status, message, error } = await postTrade(
      reqData,
      dispatch
    );
    if (status == true) {
      setFormValue(initialFormValue);
      toastAlert("success", message, "login");
      history.push("/p2p");
    } else {
      if (error) {
        setValidateError(error);
      }
      toastAlert("error", message, "login");
    }
  };

  // Initial values getting function
  // const fetchData = async () => {
  //   try {
  //     const { status, loading } = await getKycDetail(dispatch);
  //   } catch (err) {}
  // };

  const closeTrade = async (id) => {
    try {
      let reqData = {
        userId: id,
      };
      let { result } = await closeTradeDateCrossed(
        reqData
      );
      if (result) {
        console.log("Trade Closed");
      }
    } catch (err) {
      console.log("err Closetrade---", err);
    }
  };

  // const p2pSpotPair = async () => {
  //   try {
  //     const { result, status, loading } = await getP2PPairList(dispatch);

  //     if (result) {
  //       setSpotpairsdata(result);
  //       var tempdata = result;
  //       var pair = currencyselected + posttocurrency;
  //       var indexonchcange = spotpairsdata.findIndex(
  //         (x) => x.tiker_root === pair
  //       );
  //       if (indexonchcange != -1) {
  //         var onchbaseprice = spotpairsdata[indexonchcange].index_price;
  //         setCurmarketprice(onchbaseprice);
  //       }
  //     }
  //   } catch (err) {}
  // };

  // useEffect(() => {
  //   // getKycDetail(dispatch)
  // }, []);

  useEffect(() => {
    if (userKyc && userKyc) {
      console.log("userKyc---", userKyc);
      setUserId(userKyc.userId);
      if (
        (userKyc.addressProof &&
          userKyc.addressProof.status != "" &&
          userKyc.addressProof.status != "approved") ||
        (userKyc.idProof &&
          userKyc.idProof.status != "" &&
          userKyc.idProof.status != "approved")
      ) {
        toastAlert("success", "Please submit your kyc...");
        history.push("/kyc");
      }
      if (walletData && walletData.length > 0) {
        // setBalancedetails(walletData);
        var tempdata = walletData;
        var index = tempdata.findIndex(
          (x) => x.currencySymbol === currencyselected
        );
        if (index != -1) {
          console.log("Wallet data---", tempdata);
          // setUserBalance(tempdata[index].p2pbalance);
          // this.setState({ userbalance: tempdata[index].balance });
        }
      }
      if (authDetails.userId) {
        closeTrade(authDetails.userId);
        setUserId(authDetails.userId);
        getMyads(authDetails.userId);
      }
      if (spotpairsdata && spotpairsdata.length > 0) {
        setSpotpairsdata(spotpairsdata);
        var pair = currencyselected + posttocurrency;
        var indexonchcange = spotpairsdata.findIndex(
          (x) => x.tiker_root === pair
        );
        if (indexonchcange != -1) {
          // var onchbaseprice = spotpairsdata[indexonchcange].index_price;
          // setCurmarketprice(onchbaseprice);
        }
      }
    }
  }, [userKyc, walletData, spotpairsdata, authDetails]);

  const getMyads = async (user) => {
    try {
      let reqData = {
        curUser: user,
      };

      let { result, data } = await getMyRecentaddData(
        reqData
      );
      console.log("Data---", result);
      setMyaddDetails(data);
      // const { result, status, loading } = await getMyaddData(userId)
    } catch (err) { }
  };

  const cryptoHistoryColumns = [
    {
      text: "Post Id",
      className: "Post Id",
      key: "_id",
      sortable: false,
      cell: (record) => {
        return <div data-tag="allowRowEvents">{record._doc._id}</div>;
      },
    },

    {
      text: "Post Date",
      key: "orderDate",
      className: "orderDate",
      sortable: false,

      cell: (record) => {
        return (
          <div data-tag="allowRowEvents">
            {moment(record._doc.orderDate).format("DD-MM-YYYY HH:mm")}
          </div>
        );
      },
    },
    {
      text: "End Date",
      key: "EndDate",
      className: "EndDate",
      sortable: false,

      cell: (record) => {
        return (
          <div data-tag="allowRowEvents">
            {moment(record._doc.postStartDate).format("DD-MM-YYYY HH:mm")}
          </div>
        );
      },
    },
    {
      text: "Ad Type",
      className: "Ad Type",
      key: "BuyorSell",
      sortable: false,
      padding: 5,
      cell: (record) => {
        return <div data-tag="allowRowEvents">{record._doc.BuyorSell}</div>;
      },
    },
    {
      text: "Trade Pair",
      className: "Trade Pair",
      key: "pair",
      sortable: false,
      padding: 5,
      cell: (record) => {
        return <div data-tag="allowRowEvents">{record._doc.pair}</div>;
      },
    },
    {
      text: "Limit",
      className: "Limit",
      key: "maxlimit",
      sortable: false,
      padding: 5,
      cell: (record) => {
        return (
          <div>
            {record._doc.minlimit} -{record._doc.maxlimit}
          </div>
        );
      },
    },

    {
      text: "Quantity",
      className: "Quantity",
      key: "quantity",
      sortable: false,
      padding: 5,
      cell: (record) => {
        return <div data-tag="allowRowEvents">{record._doc.quantity}</div>;
      },
    },

    {
      text: "Payment Type",
      className: "Payment Type",
      key: "transferMode",
      sortable: false,
      padding: 5,
      cell: (record) => {
        return <div data-tag="allowRowEvents">{record._doc.transferMode}</div>;
      },
    },
    {
      text: "Status",
      className: "Status",
      key: "status",
      sortable: false,
      padding: 5,
      cell: (record) => {
        if (record._doc.status === "0") {
          return (
            <div>
              <button
                className="py-1 px-3 mr-2 btn-primary1 new_burron"
                onClick={() => updateMyad(record)}
              >
                Edit Ad
              </button>
              <button
                className="py-1 px-3 button_take_control new_burron"
                onClick={() => cancelMyad(record)}
              >
                Cancel Ad
              </button>
            </div>
          );
        }
        // if (record._doc.status == "2") {
        //   return <span className="textStatusGreen">Trade In Process</span>;
        // }
        // if (record._doc.status == "1") {
        //   return <span className="textStatusGreen">Completed</span>;
        // }
        // if (record._doc.status == "3") {
        //   return <span className="textWithdrawRed">Cancelled </span>;
        // }
        // if (record._doc.status == "5") {
        //   return <span className="textWithdrawRed">Disputed </span>;
        // }
        else if (record.flag_dispute > 0) {
          return <span className="textStatusGreen">Dispute In Process</span>;
        }
        else if (record._doc.status == "2" || record.flag_paid > 0 || record._doc.status == "5") {
          return <span className="textStatusGreen">Trade In Process</span>;
        }
        else if (record._doc.status == "3" && record._doc.time_expired == 1 && record.flag_dispute == 0 && record.flag_paid == 0) {
          return <span className="textWithdrawRed">Trade Expired</span>;
        }
        else if (record._doc.status == "1" && record.flag_dispute == 0 && record.flag_complete > 0) {
          return <span className="textStatusGreen">Completed</span>;
        }
        else if (record._doc.status == "3" && record._doc.time_expired != 1) {
          return <span className="textWithdrawRed">Cancelled</span>;
        }
      },
    },
  ];
  return (
    <div className="dashboardMain page_wrapper">
      <StaticNavbar />
      <ScrollToTopOnMount />
      <div className=" afterLoginHeader ">
        <Header
          className="header"
          color="transparent"
          routes={dashboardRoutes}
          brand={
            <img
              src={require("../../assets/images/logo.png")}
              alt="logo"
              className="img-fluid"
            />
          }
          rightLinks={<HeaderLinksAfterlogin />}
          fixed
          changeColorOnScroll={{
            height: 20,
            color: "dark",
          }}
          {...rest}
        />

        <div className="dashboardContent userPages peer2peer_section ">
          <div className="inner_heading_wrapper exchange_heading_wrap">
            <div className="container">
              <GridContainer>
                <GridItem md={12} data-aos="fade-up" data-aos-duration="2000">
                  <h2>P2P</h2>
                  <p>Peer-to-peer exchange (or P2P exchange) is a marketplace where people can trade crypto directly with each other on their own terms, in virtually any country.</p>
                  <div className="peer2peer_contant_first">
                    <div className="p2p_button_flex">
                        <a href="/p2p">
                        {" "}
                        <button>P2P</button>
                      </a>
                      <a href="/postmyad">
                        {" "}
                        <button>Post new Order</button>
                      </a>
                      <a href="/myads">
                        {" "}
                        <button>My Post</button>
                      </a>

                      <a href="/p2ptradehistory">
                        {" "}
                        <button>Trade History</button>
                      </a>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>
          <div className="inner_content_wrapper">
            <div className="container">
              {/* <GridContainer>
                <GridItem xs={12} sm={12} md={5} lg={5}>
                  <h3 className="dash_title">
                    Peer-to-Peer{" "}
                    <span>
                      <i class="fas fa-chevron-right arrow_key_titlw"></i>{" "}
                    </span>{" "}
                    <span className="color_gol-sd">My Ad Details</span>
                  </h3>
                </GridItem>
              </GridContainer> */}

              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <div className="dashboard_box onlytab_bordernone">
                    <div className="box_head_content">
                      <h3>My Ad Details</h3>
                    </div>
                    <div className="p2p_post_adbox">
                      <div className="table-responsive">
                        <ReactDatatable
                          className="historyCryptoTable w-100"
                          records={myaddDetails}
                          columns={cryptoHistoryColumns}
                          config={configdata}
                        // onPageChange={pageChange()}
                        />
                      </div>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>

        </div>
      </div>
      {/* <!-- Modal --> */}
      <div
        class="modal fade buy_sellaction confirm_sellaction modalzindex"
        id="edit_post"
        tabindex="-1"
        role="dialog"
        aria-labelledby="edit_post"
        aria-hidden="true"
        
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle blew">
                Edit My Post
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="confirm_body ">
                <div className="model_detail_text">
                  <div className="detail_text_model">
                    <span>Type:</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">{BuyorSell}</span>
                  </div>
                  <div className="detail_text_model">
                    <span>Cryptocurrency:</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">{currencyselected}</span>
                  </div>
                  <div className="detail_text_model">
                    <span>Quantity:</span>
                  </div>
                  <div className="detail_text_model">
                    <input
                      type="text"
                      class="form-control"
                      name="quantity"
                      value={quantity}
                      onChange={handleChange}
                      onInput={validPositive}
                    />
                    {validateError.quantity && (
                      <p className="error-message">{validateError.quantity}</p>
                    )}
                  </div>
                  <div className="detail_text_model">
                    <span>Price:</span>
                  </div>
                  <div className="detail_text_model">
                    <input
                      type="text"
                      class="form-control"
                      name="price"
                      value={price}
                      onChange={handleChange}
                      onInput={validPositive}
                    />
                    {/* {validateError.price && <p className="error-message">{(validateError.price)}</p>} */}
                  </div>
                  <div className="detail_text_model">
                    <span>Price Limit From: </span>
                  </div>
                  <div className="detail_text_model">
                    <input
                      type="text"
                      class="form-control"
                      name="postminlimit"
                      value={postminlimit}
                      onChange={handleChange}
                      onInput={validPositive}
                    />
                    {validateError.postminlimit && (
                      <p className="error-message">
                        {validateError.postminlimit}
                      </p>
                    )}
                  </div>
                  <div className="detail_text_model">
                    <span>Price Limit To: </span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">
                      <input
                        type="number"
                        class="form-control"
                        name="postmaxlimit"
                        value={posttradeprice}
                        onChange={handleChange}
                        disabled
                        onInput={validPositive}
                      />
                    </span>
                    {validateError.postmaxlimit && (
                      <p className="error-message">
                        {validateError.postmaxlimit}
                      </p>
                    )}
                  </div>
                  <div className="detail_text_model">
                    <span>Preferred Payments: </span>
                  </div>

                  <div className="detail_text_model select_box">
                
                  <input
                      type="text"
                      class="form-control"
                      name="postprefcurrency"
                      value={postprefcurrency}
                      onChange={handleChange}
                      
                    />
                    {validateError.postprefcurrency && (
                      <p className="error-message">
                        {validateError.postprefcurrency}
                      </p>
                    )}
                  
                  
                    {/* <select
                      name="postprefcurrency"
                      value={postprefcurrency}
                      onChange={handlepostprefcurrency}
                    >
                      <option value="Bank">Bank</option>
                      <option value="UPI">UPI</option>
                      <option value="Gpay">Gpay</option>
                    </select>
                    {validateError.postprefcurrency && (
                      <p className="error-message">
                        {validateError.postprefcurrency}
                      </p>
                    )} */}
                  </div>
                  

                  <div className="detail_text_model">
                    <span>Country: </span>
                  </div>
                  <div className="detail_text_model">
                  <span className="colo_fff">{country}</span>
                   
                  </div>
                  <div className="detail_text_model">
                    <span>Comments:</span>
                  </div>
                  <div className="detail_text_model">
                    <input
                      type="text"
                      class="form-control"
                      name="comments"
                      value={comments}
                      onChange={handleChange}
                      
                    />
                    {validateError.comments && (
                      <p className="error-message">{validateError.comments}</p>
                    )}
                  </div>
                </div>
                <div className="new_confirm_pay_button mat_205">
                  <button className="button1 button2" data-dismiss="modal">
                    Cancel
                  </button>
                  <button
                    className="button1 butn_new_w pr-2 pl-2"
                    onClick={updatePost}
                  >
                    Submit Post
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade buy_sellaction confirm_sellaction modalzindex"
        id="cancel_post"
        tabindex="-1"
        role="dialog"
        aria-labelledby="cancel_post"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle blew">Cancel My Post</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="confirm_body ">
                <div className="model_detail_text">
                  <div className="detail_text_model">
                    <span>Type:</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">{BuyorSell}</span>
                  </div>
                  <div className="detail_text_model">
                    <span>Cryptocurrency:</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">{currencyselected}</span>
                  </div>
                  <div className="detail_text_model">
                    <span>Price:</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">{price}</span>
                    {/* {validateError.price && <p className="error-message">{(validateError.price)}</p>} */}
                  </div>
                  <div className="detail_text_model">
                    <span>Price Limit From: </span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">{postminlimit}</span>
                    {/* {validateError.postminlimit && <p className="error-message">{(validateError.postminlimit)}</p>} */}
                  </div>
                  <div className="detail_text_model">
                    <span>Price Limit To: </span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">{postmaxlimit} </span>
                    {/* {validateError.postmaxlimit && <p className="error-message">{(validateError.postmaxlimit)}</p>} */}
                  </div>
                  <div className="detail_text_model">
                    <span>Preferred Payments: </span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">{postprefcurrency} </span>

                    {/* <Select name="postprefcurrency" value={postprefcurrency}
                            onChange={handlepostprefcurrency}>
                            <MenuItem value="Bank">Bank</MenuItem>
                            <MenuItem value="UPI">UPI</MenuItem>
                            <MenuItem value="Gpay">Gpay</MenuItem>
                          </Select>
                          {validateError.postprefcurrency && <p className="error-message">{(validateError.postprefcurrency)}</p>} */}
                  </div>
                </div>

                <h4>Are you sure want to cancel the Ad?</h4>

                <div className="new_confirm_pay_button mat_205">
                  <button className="button1 button2" data-dismiss="modal">
                    No{" "}
                  </button>
                  <button className="button1" onClick={cancelPost}>
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
      <div
        class="modal fade buy_sellaction confirm_sellaction modalzindex"
        id="confirm_post"
        tabindex="-1"
        role="dialog"
        aria-labelledby="confirm_post"
        aria-hidden="true"
        
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle blew">Confirm My Post</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="confirm_body">                
                <div className="model_detail_text">
                  <div className="detail_text_model">
                    <span>Type:</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">{BuyorSell}</span>
                  </div>
                  <div className="detail_text_model">
                    <span>Cryptocurrency:</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">{currencyselected}</span>
                  </div>
                  <div className="detail_text_model">
                    <span>Price:</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">
                      {price} {postprefcurrency}
                    </span>
                  </div>
                  <div className="detail_text_model">
                    <span>Quantity: </span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">{quantity} BTC</span>
                  </div>
                  <div className="detail_text_model">
                    <span>Limit:</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">
                      {postminlimit} {posttocurrency} - {postmaxlimit}{" "}
                      {posttocurrency}
                    </span>
                  </div>
                  <div className="detail_text_model">
                    <span>Payment Type:</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">{postprefcurrency}</span>
                  </div>
                  <div className="detail_text_model">
                    <span>Post Show Till:</span>
                  </div>
                  {/* <div className="detail_text_model">
                                  <span className="colo_fff"><moment format="DD-MM-YYYY HH-MM">{startDate}</moment></span>
                                </div> */}
                  {/* <div className="detail_text_model">
                                  <span>Post ID:</span>
                                </div>
                                <div className="detail_text_model">
                                  <span className="colo_fff">#123456</span>
                                </div> */}
                </div>
                <div className="new_confirm_pay_button mat_205">
                  <button className="button1 button2" data-dismiss="modal">
                    Cancel
                  </button>
                  <button onClick={confirmPost} className="button1">
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
