import { useEffect ,useState } from "react";
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// import { useRouteMatch } from "react-router-dom";
import {getSiteSetting} from '../../actions/commonAction'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, IconButton, Hidden, Drawer, List, ListItem, } from "@material-ui/core";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import styles from "assets/jss/material-kit-react/components/headerStyle.js";
import { Link } from "react-router-dom";
import store from "../../store";
import isLogin from "../../lib/isLogin";
const useStyles = makeStyles(styles);

export default function Header(props) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [setting, setSetting] = useState('')
  // const routeMatch = useRouteMatch();


  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  };
  const { color, rightLinks, leftLinks, brand, fixed, absolute } = props;
  // console.log("Leftlink---",leftLinks)
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  });
  const brandComponent = <Link to="/" className="logo_div">{brand}</Link>;
  const settingData = async () => {
    try {
      const { status, loading, result } = await getSiteSetting();
console.log(result,'result')
      if (status == 'success') {
        setSetting(result);
      }
    } catch (err) { }
  }
  useEffect(() => {
    
    settingData();

    // if (isAuth != true && isLogin()) {
    // } else {
    // // window.$('#exampleModalCenter').modal('show') // to show 

    // }

  }, [])


  return (
    <AppBar className={appBarClasses}>
      <Toolbar className="container-fluid home_main_nav">
        {leftLinks !== undefined ? brandComponent : null}
        <div className="d-flex align-items-center">
          {leftLinks !== undefined ? (
            <Hidden mdDown implementation="css">
              {leftLinks}
            </Hidden>
          ) : (
            brandComponent
          )}
          <Hidden mdDown>
            <List className={classes.list + " home_left_navbar"}>
            
              {/* <ListItem className={classes.listItem}>
                <a href={setting&& setting.facebookLink} color="transparent" className="nav-link"><i className="fab fa-facebook"></i></a>
              </ListItem>
              <ListItem className={classes.listItem}>
                <a href={setting && setting.twitterUrl} color="transparent" className="nav-link"><i className="fab fa-twitter"></i></a>
              </ListItem>
              <ListItem className={classes.listItem}>
                <a href={setting && setting.linkedinLink}color="transparent" className="nav-link"><i className="fab fa-linkedin"></i></a>
              </ListItem>
              <ListItem className={classes.listItem}>
                <a href={setting && setting.telegramlink}  color="transparent" className="nav-link"><i className="fab fa-telegram-plane"></i></a>
              </ListItem>
              <ListItem className={classes.listItem}>
                  <a href={setting && setting.youtubelink} color="transparent" className="nav-link"><i className="fab fa-youtube"></i></a>
                </ListItem> */}
            </List>
          </Hidden>
        </div>
        <Hidden mdDown implementation="css">
          {rightLinks}
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            className="hamburger_btn"
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Hidden lgUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper + " mobile_nav"
          }}
          onClose={handleDrawerToggle}
        >
          <div className={classes.appResponsive}>
            {leftLinks}
            {rightLinks}
          </div>
        </Drawer>
      </Hidden>
    </AppBar >
  );
}

Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};
