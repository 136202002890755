import {
    SET_AUTHENTICATION,
    REMOVE_AUTHENTICATION,
    RESET_ALL
} from "../constant";

const initialState = {
    isAuth: false,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTHENTICATION:
            return {
                ...state,
                ...action.authData
            };
        case REMOVE_AUTHENTICATION:
            return {
                ...state,
                ...action.authData
            };
            case RESET_ALL: {
                return initialState
                };  
        default:
            return state;
    }
}

export default authReducer;